"use client"

import { ReactNode, useMemo, useState } from "react"
import { motion } from "framer-motion"
import Link from "next/link"
import { Xmark } from "iconoir-react"

import ImagiNationLogo from "@/icons/ImagiNationLogo"
import { OnboardingTabTitle } from "@/components/Onboarding/Form/styles"
import AuthContext from "@/context/AuthContext"

import { LoginTabContext } from "./context"

export default function FormLayout({ children }: { children: ReactNode }) {
  const [tabLabel, setTabLabel] = useState("Sign In")

  const value = useMemo(
    () => ({ label: tabLabel, setLabel: setTabLabel }),
    [tabLabel]
  )
  return (
    <>
      <div className="mt-14 flex-grow px-4 md:px-6">
        <ImagiNationLogo
          style={{ color: "#ffe0af" }}
          className="mb-6"
          aria-label="Imagi-Nation"
          width="170"
          height="20"
        />
        <h2 className="font-heading text-[32vw] uppercase leading-[0.8] tracking-tight md:text-[20vw] lg:max-w-[85%]">
          A <span style={{ color: "#fffcde" }}>nation</span> of dreamers
        </h2>
      </div>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 0.6 }}
        exit={{ opacity: 0 }}
        className="fixed inset-0 z-30 bg-grey-900"
      >
        <Link href="/" className="absolute inset-0 block">
          <span className="sr-only">Return to home</span>
        </Link>
      </motion.div>
      <motion.div
        initial={{ x: "100%" }}
        animate={{ x: 0 }}
        className="fixed bottom-0 right-0 top-0 z-30 flex max-w-full rounded-tl-2xl bg-grey-0 text-grey-900"
        style={{ width: 400 }}
      >
        <AuthContext>
          <LoginTabContext.Provider value={value}>
            <div className="sticky top-0 h-full w-14 shrink-0 rounded-tl-2xl bg-cyan-400 text-cyan-900 md:w-20">
              <Link
                href="/"
                className="absolute left-1/2 top-5 block -translate-x-1/2 rounded-full bg-grey-900 p-2 text-grey-0"
              >
                <Xmark width="22" height="22" strokeWidth="2" />
                <span className="sr-only">Return to home</span>
              </Link>
              <OnboardingTabTitle suppressHydrationWarning>
                {tabLabel}
              </OnboardingTabTitle>
            </div>
            <div className="transparent-grey-scrollbar w-full overflow-auto px-5 py-6">
              {children}
            </div>
          </LoginTabContext.Provider>
        </AuthContext>
      </motion.div>
    </>
  )
}
