"use client"

import { createContext, useContext } from "react"

export const LoginTabContext = createContext({
  label: "Sign In",
  setLabel: (_label: string) => {},
})

export const useLoginTab = () => {
  return useContext(LoginTabContext)
}

export const LoginTabSeed = ({ label }: { label: string }) => {
  const { setLabel } = useLoginTab()
  setLabel(label)
  return null
}
