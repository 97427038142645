import clsx from "clsx"
import { motion } from "framer-motion"
import { Display8 } from "@/text"
import createStyle from "@/utils/createStyle"

export const OnboardingPage = motion(
  createStyle(
    "fieldset",
    "fixed bottom-0 right-0 top-20 z-20 w-full text-grey-900 md:top-0 md:ml-20"
  )
)

export const OnboardingPageBody = createStyle(
  "div",
  "-mr-5 flex h-full flex-col overflow-auto p-5 pr-10 pt-6"
)

export const OnboardingTab = createStyle(
  "button",
  clsx(
    "absolute p-6 text-center",
    // Mobile
    "bottom-full w-full rounded-t-full",
    // Tablet +
    "md:rounded-l-full md:rounded-tr-none",
    "md:bottom-0 md:right-full md:top-0",
    "md:w-20"
  ),
  {
    type: "button",
  }
)

export const OnboardingTabTitle = createStyle(
  "legend",
  clsx(
    Display8.classNames,
    "absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 whitespace-nowrap md:-rotate-90"
  )
)
