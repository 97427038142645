import type { FC, SVGProps } from "react"

export const ImagiNationLogo: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="95"
    height="12"
    viewBox="0 0 95 12"
    fill="currentColor"
    stroke="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    strokeWidth="0.84466"
    {...props}
  >
    <path d="M10.5666 9.55233C10.8228 8.7996 11.0473 8.13652 11.2731 7.47343C11.9617 5.44726 12.6491 3.42241 13.3441 1.39888C13.3758 1.30528 13.476 1.17082 13.5483 1.16818C14.1051 1.14841 14.6618 1.15764 15.2376 1.15764V10.8324H13.9681C14.0024 8.20243 14.0379 5.58832 14.0721 2.97552C14.0518 2.97157 14.0315 2.96761 14.0112 2.96366C13.7918 3.6162 13.5711 4.26742 13.3517 4.91996C12.7176 6.80375 12.0809 8.68623 11.4557 10.5727C11.3872 10.7796 11.301 10.8706 11.079 10.8535C10.7746 10.831 10.4639 10.8218 10.1621 10.8535C9.84246 10.8877 9.71183 10.7638 9.6091 10.4514C8.90522 8.30394 8.17851 6.16572 7.4594 4.02486C7.34399 3.68079 7.22351 3.33804 7.03961 3.00848C7.07512 5.60941 7.11063 8.21034 7.14741 10.8297H5.85759C5.85252 10.7164 5.84111 10.6004 5.84111 10.4857C5.84111 7.50112 5.84491 4.51525 5.8335 1.5307C5.8335 1.22619 5.91466 1.13127 6.20636 1.14841C6.61728 1.17346 7.02946 1.16423 7.44165 1.14973C7.63442 1.14314 7.72067 1.21828 7.78281 1.40151C8.62747 3.90226 9.47847 6.39905 10.3282 8.89716C10.3929 9.08699 10.4652 9.27286 10.5679 9.5497L10.5666 9.55233Z" />
    <path d="M89.5656 7.6174C89.142 8.62323 88.726 9.57765 88.3417 10.5439C88.2428 10.7918 88.1223 10.8709 87.8762 10.8551C87.5401 10.8326 87.2015 10.8498 86.821 10.8498C86.8667 10.718 86.8971 10.6099 86.9403 10.507C87.4704 9.25995 87.9993 8.01288 88.5395 6.77107C88.6245 6.57597 88.6068 6.45337 88.4711 6.28332C88.0411 5.74678 87.5998 5.21157 87.2332 4.6289C86.7754 3.8999 86.6346 3.08653 86.958 2.25207C87.3512 1.23964 88.4939 0.720249 89.5681 1.03663C90.5637 1.3306 91.1648 2.35753 91.0228 3.49124C90.9086 4.40216 90.5599 5.23134 90.2149 6.06185C90.1299 6.26618 90.1198 6.39932 90.2732 6.57861C91.0152 7.44602 91.7406 8.32662 92.4699 9.20459C92.5231 9.26786 92.5625 9.343 92.6094 9.41287C92.636 9.39705 92.6639 9.38255 92.6905 9.36673V1.17505H93.9994V10.8485C93.4578 10.8485 92.9226 10.859 92.3887 10.8379C92.2885 10.834 92.1756 10.7166 92.0983 10.6257C91.3132 9.6963 90.5345 8.76165 89.7533 7.82832C89.7051 7.77163 89.6543 7.71759 89.5643 7.6174H89.5656ZM89.1508 5.21421C89.1889 5.21816 89.2282 5.22212 89.2662 5.22475C89.4349 4.57089 89.6594 3.92494 89.7533 3.25922C89.823 2.76092 89.5897 2.22307 89.1166 2.11893C88.5002 1.98314 88.0284 2.41685 88.0728 3.10762C88.1274 3.97504 88.6296 4.60253 89.1508 5.21289V5.21421Z" />
    <path d="M29.0401 6.30948V5.20873C30.3401 5.15468 31.5931 5.24564 32.642 6.13151C34.1385 7.39441 33.7377 9.97161 31.9431 10.7243C29.6235 11.6985 26.5125 10.466 25.9722 7.19271C25.7236 5.68462 25.8631 4.22926 26.7192 2.92681C27.5068 1.7272 28.6292 1.12607 30.0129 1.00479C30.8689 0.929646 31.6984 1.0272 32.472 1.42531C33.8823 2.15299 34.5951 3.39875 34.8373 4.96485C34.9007 5.37087 34.9007 5.78613 34.9324 6.22379H33.6058C33.597 6.0511 33.5906 5.88104 33.5805 5.70967C33.5209 4.63924 33.2254 3.65977 32.4327 2.92418C31.1137 1.69951 29.0097 1.96844 27.9405 3.48048C26.9348 4.90157 27.0122 7.72397 28.2931 8.99609C29.1251 9.82133 30.113 10.0705 31.2088 9.72246C32.2792 9.38366 32.6153 8.0074 31.862 7.14262C31.4156 6.62981 30.8195 6.46635 30.1904 6.39253C29.815 6.34902 29.4371 6.33716 29.0363 6.30948H29.0401Z" />
    <path d="M85.4035 1.15933V2.30358C84.6108 2.38267 83.8207 2.46177 82.9443 2.55009C83.3489 2.89284 83.6799 3.15517 83.9906 3.44124C84.9241 4.30206 85.4568 5.37777 85.5126 6.67494C85.6128 9.00299 84.1593 10.7457 82.0566 10.9988C80.839 11.1452 79.7293 10.8934 78.773 10.0642C77.9055 9.31146 77.4946 8.32013 77.4337 7.17192C77.3399 5.43181 78.0806 4.10301 79.378 3.0629C79.5581 2.91789 79.7445 2.78342 79.9284 2.64369C79.9233 2.60941 79.9195 2.57646 79.9145 2.54218C79.1345 2.46441 78.3558 2.38531 77.5517 2.3049V1.14746C77.993 1.17383 78.4369 1.15142 78.8618 1.23447C79.6862 1.39529 80.4991 1.62072 81.3197 1.80396C81.4516 1.83296 81.6101 1.81187 81.7395 1.76704C82.7947 1.39793 83.8803 1.21074 84.9913 1.15933C85.1156 1.15405 85.2412 1.15933 85.4022 1.15933H85.4035ZM81.4833 9.81372C82.9278 9.81108 83.9615 8.83161 84.0946 7.33802C84.276 5.29603 83.0902 3.91318 81.6025 3.18154C81.5302 3.14595 81.411 3.1499 81.3387 3.18681C79.9741 3.88945 79.0343 4.93219 78.8529 6.56816C78.6462 8.42163 79.7788 9.81767 81.4846 9.81504L81.4833 9.81372Z" />
    <path d="M48.9264 10.8404H47.6138V1.15907C48.1312 1.15907 48.6449 1.14457 49.1572 1.17225C49.2498 1.17752 49.3513 1.35022 49.416 1.46491C50.7121 3.80219 52.0032 6.14342 53.2956 8.48202C53.4161 8.69953 53.5378 8.91573 53.7103 9.12401C53.6697 6.48353 53.6304 3.84173 53.5898 1.17884H54.9151V10.8483C54.3837 10.8483 53.8587 10.8562 53.3362 10.8391C53.2601 10.8364 53.1637 10.7283 53.118 10.6453C51.9208 8.48729 50.7299 6.32666 49.5377 4.16734C49.3196 3.77187 49.0989 3.37639 48.8148 2.99145C48.8529 5.59766 48.8896 8.20255 48.9277 10.8404H48.9264Z" />
    <path d="M22.9528 6.24102C23.0479 5.26023 23.143 4.27944 23.2432 3.2512C23.545 3.2512 23.8685 3.2512 24.1919 3.2512C24.4087 3.2512 24.3846 3.42126 24.3681 3.55704C24.2464 4.5721 24.1475 5.59243 23.9775 6.59959C23.8938 7.09789 23.6681 7.56983 23.5273 8.0589C23.493 8.18018 23.5146 8.33574 23.5539 8.46097C23.8012 9.24666 24.0612 10.0271 24.325 10.8325H22.9566C22.8361 10.4581 22.7143 10.0798 22.5812 9.66718C22.1512 10.1984 21.6617 10.6045 21.0504 10.8286C19.8303 11.2768 18.2919 11.0593 17.5107 10.0192C16.8537 9.14383 16.8093 8.14591 17.0122 7.11898C17.2456 5.93255 17.7592 4.85289 18.2729 3.77719C18.647 2.99414 19.044 2.22295 19.4093 1.43595C19.5133 1.21185 19.6388 1.13671 19.8696 1.14989C20.207 1.16834 20.5456 1.16571 20.883 1.14989C21.077 1.14198 21.1582 1.21976 21.2191 1.40431C21.6985 2.88209 22.1893 4.3559 22.6763 5.83104C22.7232 5.97209 22.7739 6.11183 22.8221 6.25156C22.8653 6.24761 22.9071 6.24366 22.9502 6.2397L22.9528 6.24102ZM20.349 2.45892C20.3135 2.4497 20.278 2.44047 20.2425 2.43124C19.701 3.67173 19.1467 4.90694 18.6242 6.15665C18.3642 6.77887 18.2234 7.43668 18.2374 8.12877C18.2627 9.37848 19.2165 10.1325 20.3845 9.81615C21.1163 9.61841 21.6211 9.12801 22.0117 8.49657C22.0878 8.37265 22.1157 8.16436 22.0739 8.02331C21.7771 7.01088 21.4588 6.00637 21.1455 5.00053C20.8817 4.15289 20.6154 3.30525 20.349 2.45892Z" />
    <path d="M62.9001 3.25269C63.2082 3.25269 63.5329 3.2395 63.8563 3.26455C63.9147 3.26851 64.0085 3.42802 64.0085 3.51502C64.0047 5.06662 63.7612 6.57471 63.207 8.02348C63.1626 8.13949 63.1588 8.299 63.1956 8.41764C63.4467 9.21387 63.713 10.0048 63.9844 10.8261H62.6147C62.4993 10.4715 62.3763 10.0958 62.2228 9.62121C62.1353 9.75304 62.0922 9.84136 62.0288 9.91123C60.9203 11.1319 59.0154 11.422 57.7471 10.5585C56.9925 10.0457 56.6513 9.28769 56.5841 8.37941C56.4953 7.17056 56.8987 6.07509 57.3717 5.01389C57.9196 3.78658 58.5195 2.58433 59.113 1.37812C59.165 1.27266 59.3274 1.17379 59.444 1.16456C59.8017 1.13688 60.1619 1.17115 60.5208 1.14874C60.7504 1.13424 60.8277 1.24366 60.8937 1.44403C61.3528 2.85985 61.822 4.2717 62.2888 5.68488C62.3522 5.87735 62.4219 6.0685 62.4891 6.25964C62.5272 6.25964 62.5665 6.25964 62.6045 6.26096C62.7022 5.27095 62.7999 4.27961 62.9013 3.25137L62.9001 3.25269ZM59.9945 2.42218C59.9361 2.496 59.8968 2.5316 59.8753 2.57642C59.4428 3.49788 58.9913 4.4088 58.5905 5.34477C58.2062 6.24119 57.8587 7.15079 57.9006 8.16981C57.9564 9.47884 58.8632 10.0787 60.0401 9.81104C60.7529 9.6489 61.2323 9.15059 61.6419 8.56001C61.77 8.37545 61.7979 8.20935 61.7256 7.98525C61.3756 6.90164 61.0459 5.81012 60.7085 4.72255C60.4739 3.9685 60.2392 3.21314 59.9932 2.4235L59.9945 2.42218Z" />
    <path d="M69.7611 3.11398C70.4194 3.94844 70.7529 4.91077 70.9127 5.92847C71.1308 7.32055 71.1968 8.71396 70.5462 10.0243C70.2088 10.7058 69.6444 11.0565 68.9038 11.0446C68.158 11.0328 67.633 10.632 67.2982 9.9584C66.9278 9.21094 66.8403 8.40153 66.8353 7.5763C66.8264 6.20135 67.0737 4.88968 67.7408 3.68347C67.8448 3.49495 67.9742 3.32094 68.1086 3.11529C67.6875 2.76991 67.198 2.58272 66.6818 2.49571C66.1643 2.40871 65.638 2.38366 65.0825 2.32697V1.16954C66.0096 1.1234 66.9126 1.21963 67.7738 1.58084C68.0934 1.7153 68.4028 1.88931 68.6856 2.09364C68.886 2.23733 69.0078 2.22942 69.2018 2.08705C70.2735 1.29741 71.4999 1.1379 72.7923 1.15899V2.36784C71.732 2.35861 70.6895 2.44166 69.7599 3.11398H69.7611ZM68.9165 3.94844C68.7516 4.31228 68.6032 4.59966 68.4878 4.90022C68.0731 5.9812 68.0642 7.11886 68.1327 8.25125C68.1581 8.67573 68.2912 9.1068 68.4485 9.5036C68.517 9.67892 68.7744 9.88853 68.9355 9.8793C69.1181 9.86875 69.3134 9.65388 69.458 9.48514C69.5557 9.37177 69.595 9.19117 69.628 9.03429C69.855 7.97705 69.8372 6.91585 69.6546 5.85596C69.5417 5.19947 69.3388 4.57593 68.9165 3.94844Z" />
    <path d="M3.59974 9.67887V10.8113C2.60416 10.9629 1.23951 10.4896 1.02898 9.15024C0.944007 8.60976 1.05054 8.10091 1.28771 7.63029C1.52106 7.16758 1.79501 6.72332 2.07529 6.2883C2.53821 5.57248 2.62318 4.85666 2.20846 4.07625C1.85462 3.41053 1.49443 2.73426 1.4399 1.94858C1.42341 1.70074 1.43736 1.45027 1.43736 1.17871H2.77284C2.69674 2.20432 3.21546 3.02296 3.60355 3.88642C3.99671 4.76175 3.95866 5.61994 3.48814 6.45176C3.21165 6.94084 2.9022 7.41146 2.63206 7.90449C2.10446 8.86286 2.38729 9.46663 3.4298 9.64987C3.48053 9.65909 3.53126 9.667 3.59974 9.67887Z" />
    <path d="M75.5579 10.84H74.2085V10.4841C74.2085 7.49952 74.2123 4.51497 74.2021 1.53175C74.2021 1.22723 74.282 1.12308 74.5737 1.15077C74.835 1.17582 75.1026 1.14154 75.3639 1.16659C75.4387 1.1745 75.5085 1.29446 75.5681 1.37355C75.5884 1.40124 75.5719 1.45792 75.5719 1.50274C75.5719 4.50838 75.5719 7.51534 75.5719 10.521C75.5719 10.6172 75.563 10.7134 75.5579 10.8413V10.84Z" />
    <path d="M38.1662 10.8367H36.832V1.15937C37.2265 1.15937 37.6234 1.1541 38.0204 1.16596C38.0686 1.16728 38.1345 1.24769 38.1561 1.3057C38.1802 1.37293 38.1675 1.45598 38.1675 1.53244C38.1675 4.51567 38.1675 7.4989 38.1675 10.4821V10.8367H38.1662Z" />
    <path d="M40.1792 6.69494V5.61133H45.5845V6.69494H40.1792Z" />
  </svg>
)
export default ImagiNationLogo
